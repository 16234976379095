import React, { useContext, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, Polygon, Popup, TileLayer, Tooltip, useMapEvents } from 'react-leaflet'
import AppServices from '../../services/general/appServices';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import L from 'leaflet';
import pin from "../../images/22.png"

const MapComponent2 = observer(({setAddress, info, deliveryZones, setAddressValue, locationSelect}) => {
    const {app} = useContext(Context);
    const mapRef = useRef();
    const [center, setCenter] = useState();

    const [isInside, setIsInside] = useState();

    const [location, setLocation] = useState();

    useEffect(() => {
        if(localStorage.getItem('branch')){
            const city = JSON.parse(localStorage.getItem('branch'));
            city?.location?.length >= 1 ? setCenter(city.location) : setCenter([51.1729850769043,71.42532348632812])
        }
    }, [localStorage.getItem('branch')])

    useEffect(() => {
        if (location && mapRef.current) {
            mapRef.current.flyTo(
                location,
                16, {
                    duration: 1
                }
            );
        }
    }, [location]);

    useEffect(() => {
        if(info && info.json_address){
            if(info.json_address.lat && info.json_address.lng){
                setLocation([info.json_address.lat, info.json_address.lng])
            }
        }
    }, [info])

    useEffect(() => {
        setLocation(locationSelect)
        if(locationSelect && mapRef?.current){
            mapRef.current.flyTo(
                locationSelect,
                16, {
                    duration: 1
                }
            );
        }
    }, [locationSelect])

    const showMyLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation([position.coords.latitude, position.coords.longitude]);
                mapRef.current.flyTo(
                    [position.coords.latitude, position.coords.longitude],
                    16, {
                        duration: 1
                    }
                );
        
            },
            (error) => {
                toast.error('Ошибка при получении местоположения:', error.message);
            }
            );
        } else {
            toast.error('Геолокация не поддерживается вашим браузером.');
        }
    };
    
    function isMarkerInsidePolygon(marker, poly) {
        var polyPoints = poly;       
        var x = marker.lat, y = marker.lng;
    
        var inside = false;
        for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            var xi = polyPoints[i][0], yi = polyPoints[i][1];
            var xj = polyPoints[j][1], yj = polyPoints[j][1];
    
            var intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = true;
        }
        setIsInside(inside)
        return inside;
    };

    const featchAddress = async (lat, lng) => {
        const res = await AppServices.address(lat, lng);
        if(res.address){
            setAddress({
                address: `${res.address.road ? res.address.road : res.address.neighbourhood} ${res.address.house_number}`,
                lat: lat,
                lng: lng
            })
            setAddressValue(`${res.address.road ? res.address.road : res.address.neighbourhood} ${res.address.house_number ? res.address.house_number : ''}`)
        }
    }

    var myIcon = L.icon({
        iconUrl: pin,
        iconSize: [41, 41],
    });

    function LocationMarker() {
        const map = useMapEvents({
            click(e) {
                setLocation([e.latlng.lat, e.latlng.lng]);
            },
        })
        
        if(location){
            featchAddress(location[0], location[1])
        }

        return (location === null || !location) ? null : (
          <Marker position={location} icon={myIcon}> </Marker>
        )
    }

  return (
    center &&
    <div className='position-relative'>
        <div onClick={showMyLocation} className='address-pin'>
            <i className='icon-location'></i>
        </div>
        <MapContainer center={center} zoom={12} ref={mapRef}>
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" minZoom={5}
            />
            {deliveryZones?.map((zone, index) =>
                <Polygon pathOptions={{ color: zone.subtitle ? zone.subtitle : 'green' }} positions={zone.zones} key={index}>
                    <Tooltip sticky>{zone.title}</Tooltip>
                </Polygon>
            )}
            <LocationMarker />
        </MapContainer>
    </div>
  )
})

export default MapComponent2