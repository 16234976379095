import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const SelectCityModal = observer(({
    show, 
    setShow, 
    setBranch,
    branches
}) => {
    const {app, user} = useContext(Context);

    const {handleSubmit, register, setValue} = useForm();

    useEffect(() => {
        if(localStorage.getItem('branch')){
            setValue('branch', localStorage.getItem('branch'))
        }
    }, [])

    const handleClose = () => {
        setShow(false);
    }

    const CustomSubmit = (data) => {
        const selectedBranch = JSON.parse(data.branch);
        app.setBranch(selectedBranch)
        setBranch(selectedBranch)
        setShow(false)
    };

  return (
    <Modal show={show} onHide={handleClose} centered className='city-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <form onSubmit={handleSubmit(CustomSubmit)}>
                <h3>{app.localizationsItems?.select_city}</h3>
                <div className='city-modal-content'>
                {Object.entries(branches)?.map(([key, value]) =>
                    <div key={key}>
                        <h4>{key}</h4>
                        {Object.entries(value).map(([key, card]) => (
                            <label className="form-check-label" key={key}>
                                <input className="form-check-input" required type="radio" 
                                    {...register('branch', {required: true})}
                                    value={JSON.stringify({ value: card.id, label: card.name, location: card.city?.value })} 
                                />
                                {card.name}
                            </label>
                        ))}
                    </div>
                )}
                </div>
                <button className='main-btn'>{app.localizationsItems?.save}</button>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default SelectCityModal