import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const NotificationTop = observer(() => {
    const {app} = useContext(Context);
    const [visible, setVisible] = useState();

    useEffect(() => {
        const work_schedule = app.settings.find(it => it.type === "work_schedule")?.value;

        if(work_schedule){
            if(work_schedule.not_accept_orders){
                setVisible(true)
            }else{
                const nowdate = new Date();
                const dayIndex = nowdate.getDay();
                let dayName = daysOfWeek[dayIndex];
                const time = `${nowdate.getHours()}:${nowdate.getMinutes()}`;

                const [hours, minutes] = time.split(':').map(Number);
                const nowTimeMinutes = hours * 60 + minutes;

                if((nowTimeMinutes > 0) && (nowTimeMinutes < 360)){
                    if(dayIndex === 0){
                        dayName = daysOfWeek[6]; 
                    }else{
                        dayName = daysOfWeek[dayIndex-1];
                    }
                }

                const schedule = work_schedule.week[dayName];
                if(!schedule){
                    setVisible(true)
                }
                if(schedule && schedule.end && schedule.begin){
                    const [startHours, startMinutes] = schedule.begin.split(':').map(Number);
                    const startOfSchedule = startHours * 60 + startMinutes;

                    const [endHours, endMinutes] = schedule.end.split(':').map(Number);
                    let endOfSchedule = endHours * 60 + endMinutes;

                    if (endOfSchedule === 0) {
                        endOfSchedule = 24 * 60;
                    }

                    if(nowTimeMinutes > 0 && nowTimeMinutes < 360){
                        setVisible(!(nowTimeMinutes <= endOfSchedule));
                    }else{
                        if(endOfSchedule < 360){
                            setVisible(!(startOfSchedule <= nowTimeMinutes && nowTimeMinutes <= endOfSchedule*24));
                        }else{
                            setVisible(!(startOfSchedule <= nowTimeMinutes && nowTimeMinutes <= endOfSchedule)); 
                        }
                    }
                }
            }
        }
    }, [app.settings]);
    
  return (
    visible ?
    <div className='notification-top'>
        <div className="container">
            <div className='notification-content'>
                <i className='icon-info'></i>
                {app.localizationsItems?.not_accent_orders}
            </div>
        </div>
    </div> : <></>
  )
})

export default NotificationTop